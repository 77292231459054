






import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { getContentPart } from '@/helpers/content-helper';
import { addCss, classesForBlock } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'NavigationItem',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPart,
      classesForBlock,
    };
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      display: flex;
      strong {
        width: 150px;
        text-align: right;
        color: ${theme.colors.primary};
        margin: 0px 10px 3px 0;
      }
      p {
        margin: 0;
      }
    `;
    },
  },
  methods: {
    navLinkClicked(e: PointerEvent) {
      e.preventDefault();
      this.$emit('navClick', {
        path: this.getContentPart('url', this.block),
        title: this.getContentPart('label', this.block),
      });
    },
    // playAudio() {
    //   const event = new CustomEvent('playAudio', {
    //     detail: {
    //       fileId: 123,
    //       url:
    //         'https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_5MG.mp3',
    //     },
    //     composed: true,
    //     bubbles: true,
    //   });
    //   self.dispatchEvent(event);
    // },
  },
  // mounted() {

  // },
});
