export class User {
  constructor(public email: string, public userId: number) {}
}

export class Block {
  constructor(
    public blockInstanceId: number,
    public name: string,
    public blockDefinitionId: number,
    public contentParts: BlockContentPart[],
    public created: string,
    public children: ChildBlock[],
    public editedChildren: ChildBlock[] | undefined,
    public childBlocks: Block[] | undefined,
    public className: string
  ) {}
}

export class BlockDefinition {
  constructor(
    public blockDefinitionId: number,
    public type: string,
    public description: string,
    public name: string,
    public contentDefinition: BlockContentPart[],
    public className: string
  ) {}
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface SelectListOption {
  name: string;
  value: string;
  selected?: boolean;
}

export interface ChildBlock {
  childBlockId: number;
  childBlockInstanceId: number;
  parentBlockInstanceId: number;
}

export class ContentImage {
  constructor(
    public fileId: number,
    public width: number,
    public height: number,
    public fileName: string,
    public fileSize: number
  ) {}
}

export class ContentAudio {
  constructor(
    public fileId: number,
    public fileName: string,
    public name: string,
    public fileSize: number
  ) {}
}

export class BlockContentPart {
  constructor(
    public id: number = 0,
    public contentPartKey: string,
    public value: string,
    public dataType: string,
    public className: string,
    public isEdited: boolean = false,
    public image?: ContentImage,
    public audio?: ContentAudio,
    public tagName?: string,
    public partOrder?: number
  ) {}
}

export interface ContentJson {
  contentItems: KeyValuePair[];
}

export class PublicState {
  constructor(
    public instances: any,
    public isGlobalLoading: boolean,
    public isLoadingBlockDefinitions: boolean,
    public rootBlock: Block | undefined, // public gooberCss: any[]
    public blockDefinitions: BlockDefinition[] | null,
    public blockDefinitionsLoadError: string | null
  ) {}
}

export enum ContentCategory {
  Audio = 'audio',
  GenericFiles = 'generic_files',
  Images = 'images',
}
