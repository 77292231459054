import Vue from 'vue';
import Vuex from 'vuex';
// import App from './App.vue';
import RootBlock from './components/RootBlock.vue';

// import vueCustomElement from 'vue-custom-element';
import wrap from '@vue/web-component-wrapper';

Vue.config.productionTip = false;
Vue.use(Vuex);

import AudioPlayer from '@/components/AudioPlayer.vue';
Vue.component('audio-player', AudioPlayer);
// Vue.use(vueCustomElement);

// Vue.customElement('site-blox', RootBlock, {
//   shadow: true,
//   beforeCreateVueInstance(root: any) {
//     console.log('beforeCreateVueInstance', root);

//     const rootNode = root.el.getRootNode();

//     if (rootNode instanceof ShadowRoot) {
//       console.debug('shadowRoot found! Using as root node ');
//       // eslint-disable-next-line no-param-reassign
//       root.shadowRoot = rootNode;
//     } else {
//       console.debug('shadowRoot not found! Using document head ');
//       // eslint-disable-next-line no-param-reassign
//       root.shadowRoot = document.head;
//     }

//     return root;
//   },
// });
const CustomElement = wrap(Vue, RootBlock);

window.customElements.define('site-blox', CustomElement as any);

// new Vue({
//   render: (h) => h(App),
// }).$mount('#app');
