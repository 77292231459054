


































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import store from '@/store';
import DateBlock from '@/components/DateBlock.vue';
import NewsItem from '@/components/NewsItem.vue';
import Copyright from '@/components/Copyright.vue';
import NavigationItem from '@/components/NavigationItem.vue';
import NavigationWrapper from '@/components/blockWrappers/NavigationWrapper.vue';
import GenericWrapper from '@/components/blockWrappers/GenericWrapper.vue';
import ConcertsWrapper from '@/components/blockWrappers/ConcertsWrapper.vue';
import GigPeriod from '@/components/GigPeriod.vue';
import Album from '@/components/Album.vue';
import AudioPlayerWrapper from '@/components/AudioPlayerWrapper.vue';
import ContentParts from '@/components/ContentParts.vue';
import * as goober from 'goober';
import { addCss, classesForBlock } from '@/helpers/style-helper';
import { Block } from '@/store/types';
import {
  definitionForBlock,
  componentForBlock,
} from '@/helpers/content-helper';

interface TheData {
  rootId: string | undefined;
  isLoading: boolean;
  data: Block | undefined;
  hide: boolean;
  classesForBlock: any;
  componentForBlock: any;
  definitionForBlock: any;
  childrenNavOptions: string[];
  selectedBlockNav: string | null;
}

export default Vue.extend({
  store,
  name: 'RootBlock',
  components: {
    DateBlock,
    AudioPlayerWrapper,
    ContentParts,
    NavigationItem,
    NavigationWrapper,
    GenericWrapper,
    NewsItem,
    ConcertsWrapper,
    GigPeriod,
    Album,
    Copyright,
  },
  props: {
    blockId: String,
    audioPlayer: Boolean,
    showOnPath: String,
    copyright: Boolean,
    childrenNavColumnName: String,
    childrenNavColumnDefault: String,
    childrenNavColumnNoFilter: String,
    hiddenPages: Boolean,
  },
  data() {
    return {
      rootId: undefined,
      isLoading: true,
      data: undefined,
      hide: false,
      childrenNavOptions: [],
      selectedBlockNav: null,
      classesForBlock,
      componentForBlock,
      definitionForBlock,
    } as TheData;
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),

    styles(): string {
      return addCss(this.instances, this.rootId)`
        font-family: Georgia, Times New Roman, Times, Serif;

        a {
          text-decoration: none;
          color: #178da3;
          cursor:pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        h2, h3, h4 {
          font-family: Arial, Helvetica, sans-serif;
          margin: 16px 0 0 0;
        }

        .full-width .children-wrapper {
          max-width: none !important;
        }

    `;
    },
  },
  watch: {
    blockDefinitions(val) {
      if (val) {
        this.fetchBlockData();
      }
    },
  },
  methods: {
    ...mapActions([
      'fetchBlock',
      'fetchBlockNav',
      'setGooberCss',
      'register',
      'fetchBlockDefinitions',
    ]),
    shouldShow(): boolean {
      if (this.showOnPath || this.hiddenPages) {
        let show = false;
        this.showOnPath.split('|').map((path: string) => {
          const hasQuery = top.location.search.indexOf('?') > -1;
          if (
            (top.location.pathname.toLowerCase() === path.toLowerCase() &&
              !hasQuery) ||
            (hasQuery && this.hiddenPages)
          ) {
            show = true;
          }
        });
        return show;
      }
      return true;
    },
    blockNavClickHandler(navItem: string) {
      this.selectedBlockNav = navItem;
      this.fetchBlockData(navItem, true);
    },
    navClickHandler(o: string): void {
      history.pushState({}, '', o);
    },
    blockType(block: Block): string | undefined {
      return definitionForBlock(block, this.blockDefinitions)?.type;
    },
    fetchBlockData(filter?: string, force?: boolean): void {
      if (this.shouldShow()) {
        this.hide = false;
        const childrenFilterColumnValue =
          filter ||
          this.childrenNavColumnDefault ||
          (this.hiddenPages &&
            top.location.search &&
            top.location.search.substring(1)) ||
          undefined;
        const childrenFilterColumnName =
          ((filter || this.childrenNavColumnDefault) &&
            this.childrenNavColumnName) ||
          (this.hiddenPages && 'url') ||
          undefined;
        if (this.blockId && this.blockDefinitions && (!this.data || force)) {
          this.fetchBlock({
            blockId: this.blockId,
            isChildBlock: false,
            childrenFilterColumnValue,
            childrenFilterColumnName,
          }).then((data) => {
            this.data = data;
            this.isLoading = false;
          });
          if (this.childrenNavColumnName) {
            this.fetchBlockNav({
              blockId: this.blockId,
              childrenNavColumnName: this.childrenNavColumnName,
            }).then((data) => {
              this.childrenNavOptions = data;
            });
          }
        }
      } else {
        this.hide = true;
      }
    },
  },
  mounted() {
    this.rootId = Math.random()
      .toString()
      .replace('.', '');
    const target = this.$refs.root;
    const css = goober.css.bind({ target: target });
    this.register({ css, rootId: this.rootId });
    this.fetchBlockDefinitions();
    this.selectedBlockNav = this.childrenNavColumnDefault;
    this.fetchBlockData();

    // Hack för att ladda om när URL ändras
    (() => {
      var previousState = window.history.state;
      setInterval(() => {
        if (previousState !== window.history.state) {
          previousState = window.history.state;
          this.fetchBlockData();
        }
      }, 100);
    })();
  },
});
