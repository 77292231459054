import axios from 'axios';
import Vue from 'vue';
import Vuex, { ActionTree, GetterTree } from 'vuex';
import { Block, BlockDefinition, ContentAudio, PublicState } from './types';
import { plainToClass } from 'class-transformer';
import { PUBLIC_API_ROOT } from './constants';

Vue.use(Vuex);

const SET_LOADING = 'SET_LOADING';
const SET_GOOBER_CSS = 'SET_GOOBER_CSS';
const REGISTER = 'REGISTER';
const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
const LOGOUT = 'LOGOUT';
const LOGIN_CHECK = 'LOGIN_CHECK';
const LOGIN_FAILED = 'LOGIN_FAILED';
const FETCH_DEFINITIONS = 'FETCH_DEFINITIONS';
const FETCH_DEFINITIONS_STARTED = 'FETCH_DEFINITIONS_STARTED';
const FETCH_DEFINITIONS_FULFILLED = 'FETCH_DEFINITIONS_FULFILLED';
const FETCH_DEFINITIONS_FAILED = 'FETCH_DEFINITIONS_FAILED';
const FETCH_OVERVIEW_FULFILLED = 'FETCH_OVERVIEW_FULFILLED';
const FETCH_OVERVIEW_FAILED = 'FETCH_OVERVIEW_FAILED';
const FETCH_BLOCK_FULFILLED = 'FETCH_BLOCK_FULFILLED';
const ADD_CHILD_FULFILLED = 'ADD_CHILD_FULFILLED';
const ADD_CHILD_FAILED = 'ADD_CHILD_FAILED';
const FETCH_BLOCK_FAILED = 'FETCH_BLOCK_FAILED';
const ADD_BLOCK_FOR_SAVING = 'ADD_BLOCK_FOR_SAVING';
const SAVE_BLOCKS_FULFILLED = 'SAVE_BLOCKS_FULFILLED';
const SAVE_BLOCKS_FAILED = 'SAVE_BLOCKS_FAILED';
const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
const SHOW_AUDIO_MODAL = 'SHOW_AUDIO_MODAL';

const defaultState = (): PublicState => {
  return {
    isGlobalLoading: false,
    isLoadingBlockDefinitions: false,
    rootBlock: undefined,
    // gooberCss: [],
    blockDefinitions: null,
    blockDefinitionsLoadError: null,
    instances: {},
  };
};

export const getters: GetterTree<PublicState, PublicState> = {
  // addCss: (state) => {
  //   return state.gooberCss || (() => '');
  // },
};

const actions: ActionTree<PublicState, PublicState> = {
  register({ commit, state }, payload: { rootId: any; css: any }) {
    commit(REGISTER, payload);
  },
  setGooberCss({ commit }, payload: { css: any; rootId: any }) {
    commit(SET_GOOBER_CSS, payload);
  },
  fetchBlockDefinitions({ commit, state }) {
    commit(SET_LOADING, true);
    if (!state.isLoadingBlockDefinitions) {
      commit(FETCH_DEFINITIONS_STARTED);
      axios
        .get(`${PUBLIC_API_ROOT}block/get_block_definitions.php`)
        .then((response) => {
          const definitions = plainToClass(BlockDefinition, response.data);
          // setTimeout(() => {
          commit(FETCH_DEFINITIONS_FULFILLED, definitions);
          // }, 1000);
        })
        .catch((err) => {
          console.log(err);
          commit(FETCH_DEFINITIONS_FAILED, err);
        })
        .finally(() => {
          commit(SET_LOADING, false);
        });
    }
  },
  async fetchBlock(
    { commit },
    payload: {
      blockId: number;
      isChildBlock: boolean;
      childrenFilterColumnName?: string;
      childrenFilterColumnValue?: string;
    }
  ) {
    const {
      isChildBlock,
      blockId,
      childrenFilterColumnName,
      childrenFilterColumnValue,
    } = payload;
    !isChildBlock && commit(SET_LOADING, true);
    let url = `${PUBLIC_API_ROOT}block/get.php?blockInstanceId=${blockId}`;
    childrenFilterColumnName &&
      (url += '&childrenFilterColumnName=' + childrenFilterColumnName);
    childrenFilterColumnValue &&
      (url += '&childrenFilterColumnValue=' + childrenFilterColumnValue);
    const req = await axios
      .get(url)
      .then((response) => {
        const block = plainToClass(Block, response.data);
        !isChildBlock && commit(FETCH_BLOCK_FULFILLED, block);
        return block;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        !isChildBlock && commit(SET_LOADING, false);
      });
    return req;
  },
  async fetchBlockNav(
    { commit },
    payload: { blockId: number; childrenNavColumnName: string }
  ) {
    const { childrenNavColumnName, blockId } = payload;

    const req = await axios
      .get(
        `${PUBLIC_API_ROOT}block/get_nav.php?blockInstanceId=${blockId}&childrenNavColumnName=${childrenNavColumnName}`
      )
      .then((response) => {
        return response.data;
        //const block = plainToClass(Block, response.data);
        //!isChildBlock && commit(FETCH_BLOCK_FULFILLED, block);
        //return block;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        //!isChildBlock && commit(SET_LOADING, false);
      });
    return req;
  },
  async fetchAudio({ commit }, payload: { fileId: number }) {
    const { fileId } = payload;

    const req = await axios
      .get(`${PUBLIC_API_ROOT}audio/get.php?fileId=${fileId}`)
      .then((response) => {
        const track = plainToClass(ContentAudio, response.data);
        return track;
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {});
    return req;
  },
};

const mutations = {
  [REGISTER](state: PublicState, payload: { rootId: any; css: any }) {
    const { rootId, css } = payload;
    state.instances[rootId] = { css };
  },
  [SET_LOADING](state: PublicState, loading: boolean) {
    state.isGlobalLoading = loading;
  },
  [FETCH_BLOCK_FULFILLED](state: PublicState, block: Block) {
    state.rootBlock = block;
    // console.log('mutation', state.rootBlock);
  },
  [FETCH_BLOCK_FAILED](state: PublicState, msg: string) {
    console.log(msg);
  },
  [FETCH_DEFINITIONS_STARTED](state: PublicState) {
    state.isLoadingBlockDefinitions = true;
  },
  [FETCH_DEFINITIONS_FULFILLED](
    state: PublicState,
    definitions: BlockDefinition[]
  ) {
    state.blockDefinitions = definitions;
    state.blockDefinitionsLoadError = null;
    state.isLoadingBlockDefinitions = false;
  },
  [FETCH_DEFINITIONS_FAILED](state: PublicState, msg: string) {
    state.blockDefinitions = null;
    state.blockDefinitionsLoadError = msg;
    state.isLoadingBlockDefinitions = false;
  },
  // [SET_GOOBER_CSS](state: PublicState, payload: { css: any; rootId: any }) {
  //   state.gooberCss = payload.css;
  //   state.counter++;
  //   console.log(SET_GOOBER_CSS, state.counter);
  // },
};

export default new Vuex.Store({
  state() {
    return { ...defaultState() };
  },
  getters,
  actions,
  mutations,
  modules: {},
});
