import { Block, BlockContentPart, BlockDefinition } from '@/store/types';
import { definitionForBlock, blockType } from '@/helpers/content-helper';

export const theme = {
  colors: {
    primary: '#a60d11',
  },
};

export function addCss(instances: any, rootId: string | undefined) {
  return (
    (instances && rootId && instances[rootId] && instances[rootId].css) ||
    (() => '')
  );
}

export function classesForBlock(
  block: Block,
  gopherStyle: string,
  definitions: BlockDefinition[]
): any {
  const c = {
    ['site-blox-' + blockType(block, definitions)]: true,
    [gopherStyle]: true,
  };
  if (block && block.className) {
    c[block.className] = true;
  }

  return c;
}

export function classForContentPart(
  contentPart: BlockContentPart,
  definition: BlockDefinition
): string {
  const partDefinition = definition.contentDefinition.find(
    (part) => part.contentPartKey === contentPart.contentPartKey
  );
  return (partDefinition && partDefinition.className) || '';
}
// const target = this.$refs.root;
//     const css = goober.css.bind({ target: target });
//     this.styles = css`
//       background-color: yellow;

//       strong {
//         color: green;
//       }
//     `;
