


















import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { theme } from '@/helpers/style-helper';
import { getContentPart, getContentPartString } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import Picture from '@/components/Picture.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
import { definitionForBlock } from '@/helpers/content-helper';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'Album',
  components: {
    HtmlDisplay,
    Picture,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPart,
      definitionForBlock,
      showReviews: false,
    };
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),
    reviews(): string | undefined {
      return getContentPartString('reviews', this.block);
    },
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin-bottom: 40px;

      h3 {
        margin-bottom: 0;

        em {
          color: ${theme.colors.primary};
          background-color: #eee;
          padding: 2px 4px 4px;
        }
      }
      p {
        margin: 0;
      }
      a.reviews-link {
        display: inline-block;
        margin: 15px 0 5px;
        font-size: 18px;
        background-color: #eee;
        padding: 5px 10px;
      }
      .reviews {
        padding: 0 0 40px 30px;
        color: #555;
      }
    `;
    },
  },
});
