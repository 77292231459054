






// @ts-nocheck
import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { genericFilePath, getContentPart } from '@/helpers/content-helper';
import { mapGetters, mapState } from 'vuex';
import { addCss } from '@/helpers/style-helper';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'HtmlDisplay',
  props: {
    html: String,
    rootId: String,
  },
  data() {
    return {
      // getContentPart,
      // audioUrl: undefined,
      // styles: '',
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin: 0 0 20px;
      width: 100%;


        .inner {
            display: block;
            max-width: 1000px;
            margin: 0 auto;
        }
    `;
    },
    // fixedHtml() {},
  },
  methods: {
    // play(audio: any) {
    //   console.log('play', audio);
    //   this.audioUrl = audio.url;
    //   document.body.className = 'audio-player';
    // },
    htmlClicked(e: PointerEvent): void {
      const clickedElm = e.target;
      const audioFileId = clickedElm.dataset.audioFileId;
      const isAudioLink =
        clickedElm.classList.contains('site-blox-audio-player') && audioFileId;
      if (isAudioLink) {
        e.preventDefault();
        const event = new CustomEvent('playAudio', {
          detail: {
            audioFileId,
          },
          composed: true,
          bubbles: true,
        });
        self.dispatchEvent(event);
      }
    },
    fixHtml(): void {
      const fileLinks = this.$refs.html.querySelectorAll(
        '.site-blox-file-link'
      );
      for (const fileLink of fileLinks) {
        const path = genericFilePath(
          fileLink.dataset.fileName,
          fileLink.dataset.contentCategory
        );
        fileLink.href = path;
        fileLink.target = '_blank';
      }

      const fileDownloads = this.$refs.html.querySelectorAll(
        '.site-blox-file-download'
      );
      for (const fileDownload of fileDownloads) {
        const path = genericFilePath(
          fileDownload.dataset.fileName,
          fileDownload.dataset.contentCategory,
          true
        );
        fileDownload.href = path;
      }
    },
  },
  mounted() {
    this.fixHtml();
  },
});
