import {
  CONTENT_FOLDER,
  PUBLIC_API_ROOT,
  PUBLIC_CONTENT_ROOT,
} from '@/store/constants';
import {
  Block,
  BlockContentPart,
  BlockDefinition,
  ContentCategory,
  ContentImage,
} from '@/store/types';
import { format, parseISO } from 'date-fns';

export function getContentPart(
  key: string,
  block: Block | undefined
): string | undefined | BlockContentPart {
  const part =
    block && block.contentParts.find((aPart) => aPart.contentPartKey === key);
  return part && ((part.image && part) || part.value);
}

export function getContentPartImage(
  key: string,
  block: Block | undefined
): BlockContentPart | undefined {
  const part =
    block && block.contentParts.find((aPart) => aPart.contentPartKey === key);
  return part;
}

export function getContentPartString(
  key: string,
  block: Block | undefined
): string | undefined {
  const part =
    block && block.contentParts.find((aPart) => aPart.contentPartKey === key);
  return part && part.value;
}

export function definitionForBlock(
  block: Block,
  definitions: BlockDefinition[]
): BlockDefinition | undefined {
  return (
    block &&
    definitions &&
    definitions.find((def) => def.blockDefinitionId === block.blockDefinitionId)
  );
}

export function genericFilePath(
  fileName: string,
  category: ContentCategory,
  download = false
) {
  const pathWithCategory = CONTENT_FOLDER[category] + fileName;
  const path = download
    ? PUBLIC_API_ROOT +
      'files/download.php?file=' +
      encodeURIComponent(pathWithCategory)
    : PUBLIC_CONTENT_ROOT + pathWithCategory;
  return path;
}

export function blockType(
  block: Block,
  definitions: BlockDefinition[]
): string | undefined {
  return definitionForBlock(block, definitions)?.type;
}

export function componentForBlock(
  block: Block,
  definitions: BlockDefinition[],
  isChild = false
): string {
  const types = {
    date: 'DateBlock',
    navigation_item: 'NavigationItem',
    navigation: 'NavigationWrapper',
    news_item: 'NewsItem',
    concerts_page: 'ConcertsWrapper',
    gig_period: 'GigPeriod',
    album: 'Album',
    copyright: 'Copyright',
    video: 'Video',
    review: 'ReviewItem',
  };
  const blockType = definitionForBlock(block, definitions)?.type || '-';
  // @ts-ignore
  let comp: string = types[blockType];
  comp = comp || (isChild ? 'GenericContent' : 'GenericWrapper');

  return comp;
}

export function formatDate(dateString: string | undefined) {
  const d = dateString && parseISO(dateString);
  return d && format(d, 'yyyy-MM-dd');
}

export function orderContentParts(
  contentParts: BlockContentPart[],
  definition: BlockDefinition
): BlockContentPart[] {
  const ordered = [...contentParts].sort((partA, partB) => {
    const definitionA = definition.contentDefinition.find(
      (def) => def.contentPartKey === partA.contentPartKey
    );
    const definitionB = definition.contentDefinition.find(
      (def) => def.contentPartKey === partB.contentPartKey
    );
    if (
      definitionA &&
      definitionB &&
      definitionA.partOrder &&
      definitionB.partOrder
    ) {
      return definitionA.partOrder > definitionB.partOrder
        ? 1
        : definitionA.partOrder < definitionB.partOrder
        ? -1
        : 0;
    }
    return 0;
  });
  return ordered;
}
