




















import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { getContentPartString } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'BlockNavigation',
  components: {
    HtmlDisplay,
  },
  props: {
    rootId: String,
    childrenNavOptions: {
      type: Array as PropType<Array<string>>,
    },
    childrenNavColumnName: String,
    childrenNavColumnNoFilter: String,
    selectedBlockNav: String,
  },
  data() {
    return {
      getContentPartString,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin: 20px 0 30px;
      a {
        display: block;
        margin-bottom: 15px;
        font-size: 20px;
        padding: 4px 8px 4px;
      }
      .selected {
        background-color: #eee;
      }
    `;
    },
  },
  methods: {
    getClass(itm?: string) {
      return this.selectedBlockNav === itm || (!itm && !this.selectedBlockNav)
        ? 'selected'
        : '';
    },
  },
});
