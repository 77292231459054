





























import Vue, { PropType } from 'vue';
import store from '@/store';
import {
  Block,
  BlockContentPart,
  ContentAudio,
  ContentImage,
} from '@/store/types';
import { format, parseISO } from 'date-fns';
import {
  getContentPartString,
  getContentPartImage,
  formatDate,
} from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import Picture from '@/components/Picture.vue';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'ReviewItem',
  components: {
    HtmlDisplay,
    Picture,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      formatDate,
      getContentPartString,
      isOpen: false,
    };
  },
  computed: {
    ...mapState(['instances']),
    img(): BlockContentPart | undefined {
      const cp = getContentPartImage('image', this.block);
      return cp;
    },
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin-bottom: 30px;

      .header {

        margin-bottom: 5px;

        h2 {
          margin: 0;
          font-size: 20px;
        }
        h3 {
          font-size: 16px;
          color: gray;
          padding-left: 20px;
          white-space: nowrap;
          margin: 0;
        }
      }
      p {
        margin: 0;
      }
      .maintext {
        padding: 0 0 0 0;
      }
      .link {
        color: gray;
        margin: 4px 0;
      }
      .read-more {
        display: inline-block;
        background-color: #eee;
        padding: 2px 6px 4px;
        margin: 4px 0;
      }
      .review-image img {
        display: block;
        margin: 10px 0;
      }
    `;
    },
  },
});
