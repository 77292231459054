

















import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format, parseISO } from 'date-fns';
import { getContentPartString, formatDate } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'NewsItem',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      formatDate,
      getContentPartString,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin-bottom: 30px;

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        h2 {
          margin: 0;
          font-size: 20px;
        }
        h3 {
          font-size: 16px;
          color: gray;
          padding-left: 20px;
          white-space: nowrap;
          margin: 0;
        }
      }
      p {
        margin: 0;
      }
      .maintext {
        padding: 0 100px 0 0;
      }
      .linktext {
        color: gray;
      }
    `;
    },
  },
});
