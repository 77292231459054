

















































import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { getContentPart, getContentPartString } from '@/helpers/content-helper';
import { addCss, classesForBlock } from '@/helpers/style-helper';
import { mapGetters, mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';
import NavigationItem from '@/components/NavigationItem.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';

export default Vue.extend({
  store,
  name: 'NavigationWrapper',
  components: {
    NavigationItem,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      mobileMenuVisible: false,
      currentPageTitle: 'News',
    };
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      header {
        position: relative;
        z-index: 100;
        margin: -54px 20px 10px;

      }
      h1, h2 {
        font-family: Georgia, Times New Roman, Times, Serif;
        font-weight: normal;
        font-size: 6vw;
        white-space: nowrap;
      }
      h1 a {
        text-decoration: none !important;
        color: black;
        font-weight: normal;
        margin-top: 18px;
      }
      h2 {
        margin-top: -3vw;
        color: ${theme.colors.primary};
      }
       nav {
        position: relative;
        top: 0;
        right: -30%;
        font-size: 16px;
        width: 70%;
        z-index: 110;

        .nav-items {
          display: none;
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100vh;
          padding: 50px 0 0 20px;
          z-index: 100;
          background-color: white;
          box-sizing: border-box;

          .site-blox-navigation_item {
            padding: 0 0 10px 0;

            a {
              display: block;
              width: 100%;
              color: black;
            }
          }

          .secondary {
            a {
              color:gray;
            }
          }
        }
        .cover {
            display: none;
            z-index: 90;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.3);
          }
        .hamburger-stuff {
          height: 50px;

          .menu-button {
            position: absolute;
            z-index: 100;
            top: 2vw;
            right: 10px;
          }
        }
        .is-mobile-visible {
          display: block !important;
        }
      }

      @media only screen and (min-width: ${MOBILE_BREAK_POINT}px) {

        header {
          margin: -124px 20px 10px;

          .header-wrapper {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1000px;
          }
        }
        h1, h2 {
          font-size: 40px;
          margin: 0;
        }
        nav {
          position: static;
          width: auto;
          font-size: 14px;
          border-top: 2px solid ${theme.colors.primary};
          border-bottom: 2px solid ${theme.colors.primary};
          padding: 5px 20px 5px 20px;
          margin: 85px 0 30px 0;

          .nav-items {
            display: block;
            position: relative;
            width: 100%;
            max-width: 1000px;
            margin: 0 auto;
            height: auto;
            padding: 0;

            .children {
              display: flex;

              .site-blox-navigation_item {
                display: inline-block;
                padding: 0 15px 0 0;
              }

              .secondary {
                flex-grow: 1;
                text-align: right;
                padding: 0 0 0 15px;
              }
              .secondary ~ .secondary{
                flex-grow: unset;
              }
            }
          }
          .hamburger-stuff, .cover {
            display: none !important;
          }
        }
    }
    @media only screen and (min-width: 1000px) {
      nav {
        font-size: 18px;
      }
      h1, h2 {
          font-size: 48px;
      }
    }
    `;
    },
    pageTitles(): { path: string; title: string }[] | undefined {
      const titles = this.block.childBlocks?.map((child) => ({
        path: getContentPartString('url', child) || '',
        title: getContentPartString('label', child) || '',
      }));
      return titles;
    },
  },
  methods: {
    navItemClicked(
      clickedItem: { path: string; title: string },
      dontToggleMenu = false
    ) {
      this.currentPageTitle = clickedItem.title;
      this.$emit('navClick', clickedItem.path);
      !dontToggleMenu && this.toggleMenu();
    },
    toggleMenu() {
      const isMobile =
        window.document &&
        window.document.body &&
        window.document.body.offsetWidth &&
        window.document.body.offsetWidth < MOBILE_BREAK_POINT;
      if (isMobile) {
        this.mobileMenuVisible = !this.mobileMenuVisible;
      }
    },
  },
  mounted() {
    // Set title to current page
    (() => {
      let previousPath = '-';
      setInterval(() => {
        if (previousPath !== top.location.pathname) {
          previousPath = top.location.pathname;
          const title = this.pageTitles?.find(
            (o) => '/' + o.path === previousPath
          )?.title;
          title && (this.currentPageTitle = title);
        }
      }, 300);
    })();
  },
});
