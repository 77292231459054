

















import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block, ContentAudio } from '@/store/types';
import { getContentPart } from '@/helpers/content-helper';
import { mapState, mapActions } from 'vuex';
import { addCss } from '@/helpers/style-helper';
// import * as goober from 'goober';
// import { css } from 'goober';

interface TheData {
  track: ContentAudio | undefined;
  getContentPart: any;
}

export default Vue.extend({
  store,
  name: 'AudioPlayerWrapper',
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPart,
      track: undefined,
      // styles: '',
    } as TheData;
  },
  computed: {
    // ...mapGetters(['addCss']),
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      width: 100%;
      height: 70px;
    `;
    },
  },
  methods: {
    ...mapActions(['fetchAudio']),
    playAudio(track: ContentAudio): void {
      this.track = track;
      document.body.className += ' audio-player';
    },
    closePlayer(): void {
      this.track = undefined;
      document.body.className = document.body.className.replace(
        / audio-player/g,
        ''
      );
    },
  },
  mounted() {
    self.addEventListener('playAudio', (e: any) => {
      this.fetchAudio({ fileId: e.detail.audioFileId }).then(this.playAudio);
    });
  },
});
