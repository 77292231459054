


































import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import {
  getContentPart,
  componentForBlock,
  definitionForBlock,
} from '@/helpers/content-helper';
import { addCss, classesForBlock } from '@/helpers/style-helper';
import { mapGetters, mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';

import BlockNavigation from '@/components/BlockNavigation.vue';
import GenericContent from '@/components/GenericContent.vue';
import NewsItem from '@/components/NewsItem.vue';
import ReviewItem from '@/components/ReviewItem.vue';
import Copyright from '@/components/Copyright.vue';
import Video from '@/components/Video.vue';
import Album from '@/components/Album.vue';
import NavigationItem from '@/components/NavigationItem.vue';
import GigPeriod from '@/components/GigPeriod.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';

// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'GenericWrapper',
  components: {
    NewsItem,
    NavigationItem,
    GigPeriod,
    Album,
    Copyright,
    Video,
    ReviewItem,
    BlockNavigation,
    GenericContent,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
    childrenNavOptions: {
      type: Array as PropType<Array<string>>,
    },
    childrenNavColumnName: String,
    childrenNavColumnNoFilter: String,
    selectedBlockNav: String,
  },
  data() {
    return {
      componentForBlock,
      definitionForBlock,
      // getContentPart,
      // classesForBlock,
    };
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),
    styles(): string {
      const s = addCss(this.instances, this.rootId)`
        margin: 30px 20px 0;

        .children-wrapper {
          display: flex;
          flex-wrap: wrap;
          max-width: 1000px;
          margin: 0 auto;

          .children {
            width: 100%;
          }
          .children-with-block-navigation  {

          }

          .press-photo {
            display: inline-block;
            margin: 0 20px 30px 0;
            vertical-align: top;

            img {
              width: 300px;
            }
          }

        }

        @media only screen and (min-width: ${MOBILE_BREAK_POINT}px) {
          margin: 80px 20px 0;

          .children-wrapper {
          }
          .with-block-navigation {
            flex-wrap: nowrap;
            flex-direction: row-reverse;

            .children {
              max-width: 700px;
            }

            .block-navigation {
              width: 270px;
              margin-left: 30px;
            }

          }
        }
      `;
      return s;
    },
  }, // methods: {
});
