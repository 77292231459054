






import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
import { format } from 'date-fns';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'Copyright',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  computed: {
    ...mapState(['instances']),
    year() {
      return format(new Date(), 'yyyy');
    },
    styles(): string {
      return addCss(this.instances, this.rootId)`
        color: #aaa;
        margin: 60px 20px 20px;

        span {
            display: block;
            max-width: 1000px;
            margin: 0 auto;
        }
    `;
    },
  },
});
