import { ContentCategory } from './types';

export const MOBILE_BREAK_POINT = 790;
export const PUBLIC_API_ROOT = '//public.site-blox.com/api/public/';
export const PUBLIC_CONTENT_ROOT = '//content.site-blox.com/';
export const CONTENT_FOLDER = {
  [ContentCategory.Audio]: 'audio/',
  [ContentCategory.GenericFiles]: 'generic/',
  [ContentCategory.Images]: 'images/',
};
