






import Vue, { PropType } from 'vue';
import store from '@/store';
import { addCss } from '@/helpers/style-helper';
import { Block, BlockContentPart } from '@/store/types';
import { getContentPart } from '@/helpers/content-helper';
import { mapGetters, mapState } from 'vuex';
import { PUBLIC_CONTENT_ROOT } from '@/store/constants';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'Picture',
  props: {
    contentPart: { type: Object as PropType<BlockContentPart> },
    rootId: String,
  },
  data() {
    return {
      // getContentPart,
      // audioUrl: undefined,
      // styles: '',
    };
  },
  computed: {
    ...mapState(['instances']),
    styles() {
      const s = addCss(this.instances, this.rootId)`
        img {
          max-width: 100%;
        }
      `;
      return s;
    },
    imgSrc() {
      return (
        PUBLIC_CONTENT_ROOT + 'images/' + this.contentPart?.image?.fileName
      );
    },
  },
  mounted() {
    // console.log('Image mounted', this.contentPart);
  },
});
