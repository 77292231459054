




























import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block, BlockContentPart, BlockDefinition } from '@/store/types';
import { format } from 'date-fns';
import {
  getContentPartString,
  orderContentParts,
} from '@/helpers/content-helper';
import { addCss, classForContentPart } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import Picture from '@/components/Picture.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'GenericContent',
  components: {
    HtmlDisplay,
    Picture,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
    definition: Object as PropType<BlockDefinition>,
  },
  data() {
    return {
      getContentPartString,
      orderContentParts,
      classForContentPart,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`

      .main-image {
        margin: 0 -20px 30px;

        img {
          width: 100%;
          min-height: 110px;
          object-fit: cover;
        }
      }
    `;
    },
  },
  methods: {
    tagNameForPart(part: BlockContentPart) {
      return this.definition.contentDefinition.find(
        (def) => def.contentPartKey === part.contentPartKey
      )?.tagName;
    },
  },
});
