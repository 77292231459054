




























import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block, BlockDefinition } from '@/store/types';
import { format } from 'date-fns';
import { getContentPart, orderContentParts } from '@/helpers/content-helper';
import { addCss, classForContentPart } from '@/helpers/style-helper';
import { mapGetters, mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import Picture from '@/components/Picture.vue';
import { BlockContentPart, ContentAudio, ContentImage } from '@/store/types';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'ContentParts',
  components: {
    HtmlDisplay,
    Picture,
  },
  props: {
    contentParts: {
      type: Array as PropType<Array<BlockContentPart>>,
    },
    definition: Object as PropType<BlockDefinition>,
    rootId: String,
  },
  data() {
    return {
      getContentPart,
      orderContentParts,
      classForContentPart,
    };
  },
  computed: {
    ...mapState(['instances', 'blockDefinitions']),
    styles(): string {
      return addCss(this.instances, this.rootId)`

      strong {
        width: 150px;
        text-align: right;
        color: ${theme.colors.primary};
        margin: 0px 10px 3px 0;
      }
      p {
        margin: 0;
      }
      .main-image {
        margin: 0 -20px 30px;

        img {
          width: 100%;
          min-height: 110px;
          object-fit: cover;
        }
      }

    `;
    },
  },
  mounted() {
    // console.log(this.definition, this.contentParts);
  },
});
