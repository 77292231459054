
















import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { getContentPartString } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'Video',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPartString,
    };
  },
  computed: {
    ...mapState(['instances']),
    youtubeUrl(): string | null {
      const videoUrl = getContentPartString('videoUrl', this.block);
      if (videoUrl) {
        const m = videoUrl.match(/(^|=|\/)([0-9A-Za-z_-]{11})(\/|&|$|\?|#)/);
        if (m && m.length > 2) {
          return m[2];
        }
      }
      return null;
    },
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin-bottom: 30px;

      .videoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
      }
      .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `;
    },
  },
  mounted() {
    // console.log(this.block);
  },
});
