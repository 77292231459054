
















// @ts-nocheck
import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { getContentPartString } from '@/helpers/content-helper';
import { addCss, classesForBlock } from '@/helpers/style-helper';
import { mapGetters, mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';
import DateBlock from '@/components/DateBlock.vue';
import HtmlDisplay from '@/components/HtmlDisplay';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'ConcertsWrapper',
  components: {
    DateBlock,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPartString,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      const s = addCss(this.instances, this.rootId)`
        margin: 0 20px;

        .children {
          max-width: 1000px;
          margin: 0 auto;
        }

        h3 {
          margin: 30px 0 10px;
        }

        @media only screen and (min-width: ${MOBILE_BREAK_POINT}px) {
          margin: 70px 20px 0;
        }
      `;
      return s;
    },
  },
  methods: {
    yearFromDate(date: string | undefined): string | undefined {
      if (date) {
        const dateObj = new Date(date);
        return format(dateObj, 'yyyy');
      }
    },
    isNewYear(childIndex: number): boolean {
      const theYear = this.yearFromDate(
        getContentPart('date', this.block.childBlocks[childIndex])
      );
      if (childIndex > 0) {
        const prevYear = this.yearFromDate(
          getContentPart('date', this.block.childBlocks[childIndex - 1])
        );
        return theYear !== prevYear;
      } else {
        return true;
      }
    },
  },
  mounted() {
    // console.log(this.block.childBlocks);
  },
});
