







import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { format } from 'date-fns';
import { getContentPartString } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapState } from 'vuex';
import HtmlDisplay from '@/components/HtmlDisplay.vue';
import { MOBILE_BREAK_POINT } from '@/store/constants';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'GigPeriod',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPartString,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      margin-bottom: 30px;

      p {
        margin: 5px;
      }
      em {
        font-style: normal;
        font-weight: bold;
        color: #a60d11;
        display: block;
      }

      @media only screen and (min-width: ${MOBILE_BREAK_POINT}px) {
        padding-left: 220px;

        em {
          float: left;
          width: 200px;
          text-align: right;
          margin-right: 20px;
        }
        h2, em {
          margin-left: -220px;
        }
      }
    `;
    },
  },
});
