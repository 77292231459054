









// @ts-nocheck
import Vue, { PropType } from 'vue';
import store from '@/store';
import { Block } from '@/store/types';
import { getContentPartString, formatDate } from '@/helpers/content-helper';
import { addCss } from '@/helpers/style-helper';
import { mapGetters, mapState } from 'vuex';
import { theme } from '@/helpers/style-helper';
import HtmlDisplay from '@/components/HtmlDisplay';
// import * as goober from 'goober';
// import { css } from 'goober';

export default Vue.extend({
  store,
  name: 'DateBlock',
  components: {
    HtmlDisplay,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
    },
    rootId: String,
  },
  data() {
    return {
      getContentPartString,
      formatDate,
    };
  },
  computed: {
    ...mapState(['instances']),
    styles(): string {
      return addCss(this.instances, this.rootId)`
      display: flex;
      strong {
        width: 150px;
        text-align: right;
        color: ${theme.colors.primary};
        margin: 0px 10px 3px 0;
      }
      p {
        margin: 0;
      }
    `;
    },
  },
});
