<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://web.resource.org/cc/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://inkscape.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    :width="width"
    :height="width"
    viewBox="0 0 48 48"
    id="svg2"
    sodipodi:version="0.32"
    inkscape:version="0.43"
    version="1.0"
    sodipodi:docbase="/home/luca"
    sodipodi:docname="dialog-close.svg"
  >
    <title lang="en">{{ title }}</title>
    <defs id="defs4" />
    <sodipodi:namedview
      id="base"
      :pagecolor="color"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="15.895833"
      inkscape:cx="24"
      inkscape:cy="24"
      inkscape:document-units="px"
      inkscape:current-layer="layer1"
      showgrid="true"
      inkscape:grid-bbox="true"
      inkscape:grid-points="true"
      showguides="true"
      inkscape:guide-bbox="true"
      inkscape:window-width="1268"
      inkscape:window-height="947"
      inkscape:window-x="6"
      inkscape:window-y="46"
    />
    <metadata id="metadata7">
      <rdf:RDF>
        <cc:Work rdf:about="">
          <dc:format>image/svg+xml</dc:format>
          <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        </cc:Work>
      </rdf:RDF>
    </metadata>
    <g inkscape:label="Livello 1" inkscape:groupmode="layer" id="layer1">
      <path
        sodipodi:type="arc"
        style="fill:#000000;fill-opacity:1;stroke:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        id="path1307"
        sodipodi:cx="24"
        sodipodi:cy="24"
        sodipodi:rx="22"
        sodipodi:ry="22"
        d="M 46 24 A 22 22 0 1 1  2,24 A 22 22 0 1 1  46 24 z"
      />
      <path
        :style="
          `fill:none;fill-opacity:0.75;fill-rule:evenodd;stroke:${color};stroke-width:6;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`
        "
        d="M 16.221825,16.221825 L 31.778175,31.778175"
        id="path2186"
        sodipodi:nodetypes="cc"
      />
      <path
        sodipodi:nodetypes="cc"
        id="path3936"
        d="M 31.778175,16.221825 L 16.221825,31.778175"
        :style="
          `fill:none;fill-opacity:0.75;fill-rule:evenodd;stroke:${color};stroke-width:6;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1`
        "
      />
      <rect
        style="fill:none;fill-opacity:1;stroke:none;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
        id="rect3938"
        width="48"
        height="48"
        x="0"
        y="0"
      />
    </g>
  </svg>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'Close',
  props: {
    title: {
      type: String,
      default: 'Close',
    },
    width: {
      type: [Number, String],
      default: 48,
    },
    height: {
      type: [Number, String],
      default: 48,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
});
</script>
